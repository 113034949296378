.c-mix-campaign {
  background-color: #0072bb;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 3px;
  position: relative;
  top: 0px;
  left: 0;
  width: 90px;
  z-index: 1;

  &__blue-box-arrow {
    background: url('./blue_box_arrow.svg') no-repeat;
    background-size: contain;
    bottom: -19px;
    display: inline-block;
    height: 20px;
    left: 20px;
    position: absolute;
    transform: rotateY(180deg);
    width: 20px;
    z-index: 999;
  }
  &__text-wrapper {
    padding: 4px;
    p {
      color: #fff;
      font-size: 10px;
      text-align: center;
      line-height: 1.2;
    }
  }
}

.c-mix-campaign-badge {
  background-color: #0072bb;
  border-radius: 5px;
  padding: 2px 7px;
  color: #fff;
  margin-top: 5px;
  display: inline-block;
}
