.nav {
  background-color: #5f5f5f;
  min-height: 100%;
}

body .buorg {
  background-color: white;
}

body .buorg-buttons {
  margin-top: 12px;
  margin-bottom: 12px; 
}

a {
  -webkit-tap-highlight-color: transparent;
}
  
a:focus,a:visited,a:active{
  outline: none;
}