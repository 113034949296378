@import '../../styles/_variables.scss';

.cart {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  justify-content: space-between;
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;

  .scrollable {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #f7f7f7;

    .scrollable__container {
      // for mobile overscroll
      background: #fff;

      @media(min-width:1360px) {
        background: #f7f7f7;
      }
    }
  }

  .cart-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: #F7F7F7;
  }

  .cart-list__items {
    background: #fff;
  }

  .c-total {
    font-size: 15px;
    font-weight: 500;

    .c-total-count {
      font-size: 13px;
      font-weight: 400;
      color: #8F8F8F
    }
  }

  .c-discount {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 400;
  }

  .c-discount-price {
    color: #E02721;
  }

  .c-clear-cart {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    padding-bottom: 8px;
    padding-right: 10px;
    background: #F7F7F7;

    &__label {
      font-size: 13px;
      font-weight: 500;
    }
  }

  .cart-loading {
    padding: 60px 0;
  }

  .cart-empty-state {
    padding: 15px;
    font-size: 15px;
    text-align: center;
    max-width: 255px;
    margin: 0 auto;
    padding-bottom: 100px;

    a {
      color: $color-blue-light;
      text-decoration: underline;
    }

    p {
      font-weight: 400;
      margin-bottom: 15px;
    }

    &__text {
      font-weight: 600;
      padding: 15px 0;
      display: block;
    }

    &__links {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      align-items: center;
      justify-content: center;

    }

    &__divider {
      margin: 24px 0;
    }

  }

  .cart-notloggedin {
    padding-top: 32px;
    padding-bottom: 15px;
    font-size: 15px;
    max-width: 225px;
    margin: 0 auto;
    text-align: center;
  }

  .cart-notloggedin {
    padding-top: 32px;
    padding-bottom: 15px;
    font-size: 15px;
    max-width: 225px;
    margin: 0 auto;
    text-align: center;
  }

  .cart-card {
    padding: 16px 0;
    margin: 0 16px;
    border-bottom: 1px solid #E8E8E8;

    &__last-card {
      border: none;
    }

    &__description {
      font-size: 13px;
      color: #333;
    }

    &__description-nowrap {
      text-overflow: ellipsis;
    }

    .recipe-labels {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
    }
  }

}

.cart-footer {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: calc(100% - 2px);

  border-radius: 8px;
}

.cart-footer__container {
  background: #fff;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.06);

}

.cart-footer__scrolled {
  box-shadow: none;

}

.cart-summary__outer-container {
  padding-top: 8px;
  background: #f7f7f7;
}

.cart-summary__container {
  @media(max-width: 480px) {
    visibility: unset !important;
  }

}

.cart-footer__summary {
  position: relative;
  border-radius: 8px;
  z-index: 1;
  display: none;

  @media(min-width: 480px) {
    display: block;
  }
}

.c-divider {
  border-top: 1px solid #E8E8E8;
  margin: 0 16px;
  position: relative;
  z-index: 2;
  background: #fff;
}

.cart-footer-summary-total {
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  gap: 8px;
  position: relative;
  z-index: 2;
  background: #fff;
  border-radius: 8px;

  button {
    svg {
      margin-bottom: 2px;
    }
  }
}

.c-progress-wrapper {
  border-top: unset;
  padding: 10px 0;
  background: #fffbe7;

}

.c-progress {
  height: 20px;
  padding: 8px;
  display: flex;
  justify-content: center;
  background: #fffbe7;


  .c-progress-container {
    background: white;
    border-radius: 4px;
    width: 100%;
    height: 5px;
    overflow: hidden;
  }

  .c-progress-bar {
    background: $color-blue;
    height: 100%;
  }

}

.c-checkout-btn__label {
  display: flex;
  align-items: center;
  margin-left: 15px;
}