
.header-delivery-notice { 
    max-width: 1312px;
    width: 100%;
    position: relative;

    @media (min-width: 991px){
      width: 100%;    
    }

    &__text {
      display:flex;
      flex-direction: column;
      width: 100%;
      padding-right: 40px;
      gap: 16px;
  
      @media (min-width: 768px){
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
      }
    
    }
    &__link{
      text-decoration: underline;
    }
    &__close {
      position:absolute;
      right: 8px;
      top: 4px;
      cursor: pointer;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      display: grid;
      place-content: center;
  
      &:hover{
        background:#F1F1F1;
      }
    }
  }
  