@import '../../../styles/variables';

.o-offerDetail {
  background: $color-white;
  margin-bottom: 16px;
  padding: 16px;

  @media (min-width: $screen-tablet) {
    display: flex;
    gap: 32px;
    padding: 16px 24px;
  }

  &__image-container {
    display: grid;
    height: 184px;

    @media (max-width: $screen-tablet) {
      margin: 0 auto 16px;
      place-content: center;
    }

    @media (min-width: $screen-tablet) {
      align-content: start;
      width: 248px;
      height: 248px;
    }

    > div {
      max-height: 184px;

      @media (min-width: $screen-tablet) {
        max-height: 248px;
      }
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div:last-of-type {
      @media (min-width: $screen-tablet) {
        display: inline-flex;
      }
    }
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
    line-height: 25.5px;

    @media (min-width: $screen-tablet) {
      font-size: 30px;
      font-weight: 700;
    }
  }

  &__subTitle,
  &__description {
    font-size: 15px;
    line-height: 22.5px;
  }

  &__price-info {
    margin-top: 8px;
  }

  &__text-small {
    font-size: 12px;
  }

  &__text-small-medium {
    font-weight: 500;
  }

  &__text {
    font-size: 13px;
  }

  &__text-medium {
    font-weight: 500;
  }
}

.o-offerProducts {
  background-color: $color-black-lightest;
  padding-bottom: 6px;

  @media (min-width: $screen-tablet) {
    padding-bottom: 24px;
  }

  &__title-no-flyout {
    font-size: 15px;
    margin-bottom: 16px;
    padding: 0 16px;

    @media (min-width: $screen-tablet) {
      font-size: 18px;
      margin: 24px 0;
      padding: 0;
    }
  }

  &__title-flyout {
    padding: 16px 24px 8px;
  }

  &__offer-product {
    background: $color-white;
    padding: 12px 12px 0;

    &::after {
      border-bottom: 1px solid $color-button-grey-static;
      content: '';
      display: block;
      padding-top: 11px;
      width: 100%;
      height: 1px;
    }
  }

  &__offer-product-flyout {
    @media (min-width: $screen-tablet) {
      margin: 0 24px;
    }
  }

  &__offer-product-last {
    padding: 12px 12px;

    &::after {
      content: '';
      display: none;
    }
  }
}
