.c-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 800px;
  right: 0px;
  background: white;
  z-index: 4;
  overflow: hidden;

  &.nopadding .pnlcontent {
    padding: 0;
  }

  > div {
    &.slideleft,
    &.slideright {
      width: 100%;
      position: absolute;
      transition: ease-in-out 0.2s;
    }

    &.slideleft {
      left: -100%;

      &.active {
        left: 0;
      }
    }

    &.slideright {
      right: -100%;

      &.active {
        right: 0;
      }
    }
  }
}
.k-panel {
  transition: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  min-height: 400px;
  height: 100%;
  @media screen and (min-width: 991px) {
    height: fit-content;
    border-radius: 8px;
    top: 50%;
    left: 50%;
    bottom: unset;
    right: unset;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 48px - 48px);
  }
}

.flyout-animation-enter {
  transform: translateX(800px);
}
.flyout-animation-enter-active {
  transform: translateX(0px);
  transition: ease-in-out 0.2s;
}

.flyout-animation-exit {
  transform: translateX(0);
}
.flyout-animation-exit-active {
  transform: translateX(800px);
  transition: ease-in-out 0.2s;
}

@media screen and (max-width: 991px) {
  .c-panel {
    width: calc(100% - 40px);
  }
  .k-panel {
    width: 100%;
  }
}

@media screen and (max-width: 321px) {
  .c-panel {
    width: 100%;
  }
}

@media screen and (min-width: 450px) {
  .c-panel.tight {
    right: 0px;
    width: 370px;

    > div {
      width: 370px;
    }
  }
  /* don't animate width of containing panel when initially opened */
}

.c-stdpnlcnt {
  height: 100%;
  position: relative;
  background: #f7f7f7;

  &.light {
    background: white;
  }
  &.offer-header {
    background: #fff;
  }
  .pnlhead {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0);
    overflow: hidden;
    transition: box-shadow 150ms ease-in-out;
    &--is-scrolled {
      box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.175);
      position: relative;
      z-index: 1;
    }
  }

  .prevpanel,
  .closepanel {
    position: absolute;
    z-index: 1;
  }

  .pnlhead h2 {
    font-weight: 600;
    width: calc(100% - 40px);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 768px) {
      text-align: center;
    }

    .time {
      font-size: 16px;
    }
  }

  .pnlcontent {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .pnlfoot {
    height: 56px;
    background: #fffbe7;
    border-top: 3px solid #ffe522;

    .c-cmdbtn {
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 767px) {
  .c-stdpnlcnt {
    .pnlhead {
      height: 59px;
      padding: 17px 10px 0;
    }

    &.has-prevpanel .pnlhead {
      height: calc(59px + 40px);
      padding-top: calc(17px + 40px);
    }

    .prevpanel {
      left: 10px;
      top: 10px;
    }

    .closepanel {
      right: 13px;
      top: 13px;
    }

    .pnlhead h2 {
      font-size: 23px;
    }

    .pnlcontent {
      height: calc(100% - 59px);
      padding: 0 10px;
    }

    &.has-prevpanel .pnlcontent {
      height: calc(100% - 59px - 40px);
    }

    &.has-foot .pnlcontent {
      height: calc(100% - 59px - 56px);
    }

    .pnlfoot {
      padding: 10px 10px 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .c-stdpnlcnt {
    .pnlhead {
      height: 59px;
      padding: 18px 20px 0;
    }

    &.has-prevpanel .pnlhead {
      height: calc(69px + 40px);
      padding-top: calc(18px + 40px + 10px);
    }

    .prevpanel {
      left: 20px;
      top: 20px;
    }

    .closepanel {
      right: 15px;
      top: 13px;
    }

    .pnlhead h2 {
      font-size: 23px;
    }

    .pnlcontent {
      height: calc(100% - 69px);
      padding: 0 20px;
    }

    &.nohead .pnlcontent {
      height: 100%;
    }

    &.has-prevpanel .pnlcontent {
      height: calc(100% - 69px - 40px);
    }

    &.has-foot .pnlcontent {
      height: calc(100% - 69px - 56px);
    }

    .pnlfoot {
      padding: 10px 20px 0;
    }
  }
}
