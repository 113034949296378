@import '../../styles/variables';
.p-carousel_card{
  height: 100%;
}
.productcard-container {
  background-color: #fff;
  box-shadow: 0 1px 0 0 #d7d7d7;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  transition: box-shadow 0.3s ease;

  &:hover {
    color: #333;
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.1);
  }

  .product-card {
    &__upper-container {
      display: block;
      position: relative;
      .product-image {
        display: flex;
        margin: auto auto 15px auto;
        height: 170px;
        max-width: 150px;
      }

      .availability {
        height: 24px;
        display: inline-flex;
        right: 0;
        top: 0;
        position: absolute;
        img {
          margin-top: -2px;
        }
      }

      .markings {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        display: inline-block;

        img {
          height: auto;
          width: 100%;
          background: #fff;
          border-radius: 100%;
          padding: 5px;
        }
        &.lower-position-top {
          top: 29px;
        }
      }
    }
    &__lower-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      .details {
        width: 100%;
        p,
        h3 {
          line-height: 1;
        }

        h3 {
          color: rgb(123, 123, 123);
          font-size: 12px;
          font-weight: 300;
        }

        .h3 {
          font-size: $font-size-base;
        }

        p {
          color: rgb(123, 123, 123);
          font-size: 10px;
          margin-bottom: 10px;
          text-align: center;
        }

        &__name {
          line-height: 1.3;
          margin-bottom: 5px;
          word-wrap: break-word;

          @media screen and (max-width: $screen-xs) {
            margin: 0 auto 0.123rem;
          }
        }
      }

      .price-section {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .price-comparison {
        font-size: 10px;
        display: flex;
        flex-direction: column-reverse;
        > span {
          display: block;
          width: 100%;
          &.ordinal-price {
            order: 3;
          }
          &.ordinal-compare-price {
            order: 2;
          }
          &.compare-price {
            order: 1;
          }
        }
        .bold {
          font-weight: bold;
        }
        .grey {
          color: rgb(123, 123, 123);
        }
      }

      .max-amount {
        font-size: 10px;
        color: #e4777a;
        text-align: center;
        margin-bottom: 4px;
        transition: 0.3s;
      }

      .cartactions {
        position: relative;
        width: 100%;
        display: block;
        height: 31px;
        .buy-button {
          position: absolute;
          top: 0;
          width: 100%;
          .changeqty,
          .addtocart {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
          }
          .changeqty {
            justify-content: space-between;
            width: 100%;
            max-width: 140px;
            margin: auto;
          }
          .c-cmdbtn {
            display: flex;
            justify-content: center;
            line-height: 31px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .product-card-price-filler {
    display: flex;
    min-height: 20px;
  }
}
