@import '../../../styles/variables';

/* Component: product details */
.c-productdetails {
  position: relative;

  .details-block {
    background: #fff;
    display: flex;
    line-height: 1.3;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    position: relative;

    .push-to-bottom {
      height: auto;
      margin-top: 10px;
    }

    h1 {
      margin-top: 0;
    }

    .left {
      display: flex;
      flex-direction: column;
    }

    .right {
      max-width: 100%;

      .image img {
        height: auto;
        max-width: 150px;
      }
    }

    .markings {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .price-comparison {
      font-size: 10px;

      .bold {
        font-weight: bold;
      }

      .grey {
        color: rgb(123, 123, 123);
      }
    }

    .max-amount {
      font-size: 10px;
      color: #e4777a;
      margin-top: 4px;
      margin-bottom: 4px;
      transition: 0.3s;
    }

    .visible {
      opacity: 1;
    }

    .hidden {
      opacity: 0;
    }

    .save {
      background-color: $color-red;
      color: $color-white;
      display: inline-block;
      line-height: 20px;
      padding: 0 10px;
      margin-top: 5px;
      z-index: 1;

      p {
        font-size: 10px;
        text-transform: uppercase;
      }

      span {
        text-transform: none;
      }

      p,
      span {
        font-weight: 300;
      }
    }

    .size {
      font-size: 14px;
      margin-bottom: 15px;
    }

    .shop {
      &__price-wrapper {
        display: inline-flex;
        flex-direction: column;
        position: relative;
      }

      &__disclaimer {
        margin-top: 10px;

        p {
          font-size: 10px;
        }
      }

      .c-pricetag {
        &.isExtraPrice {
          align-items: center;
          display: flex;
          justify-content: center;
          padding: 4px 10px;

          &--is-mix {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        &__effect-type {
          margin: 5px 0 -10px -25px;
          flex-direction: column;
        }

        .value__fraction-unit-wrapper {
          .unit {
            margin-top: -6px;
          }
        }
      }

      .c-cmdbtn {
        position: relative;
      }

      .meta {
        z-index: 1;
        margin-top: 0;
      }

      .meta-save {
        margin-top: -15px;
      }
    }

    .actions {
      align-items: center;
      display: flex;
      height: 100%;
      margin-bottom: auto;

      .c-favoritebtn {
        margin: 0 0 0 15px;
        position: unset;
      }
    }
  }

  &__offer-details {
    font-size: $font-size-xxs;
  }
}

.pnlcontent .c-productdetails .details-block {
  margin: 0px 20px;
  width: calc(100% - 40px);

  .left {
    flex: 1 1 auto;
    align-items: flex-start;
  }
}

.c-productdetails .details-block .logos img {
  margin-right: 5px;
  height: 30px;
}

.c-productdetails .details-block .warning {
  font-size: 14px;
  font-weight: 500;
  padding: 20px;
  color: #e42313;
  background: #fadedd;
  margin-bottom: 15px;
  display: inline-block;
}

.c-productdetails .details-block .warning img {
  margin-left: 2px;
}

.c-productdetails .details-block .shop .compareprice {
  font-size: 12px;
}

.c-productdetails .details-block .shop .saveprice {
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 3px;
  font-size: 12px;
  color: #e42313;
}

.c-productdetails .details-block .shop .ordprice {
  color: #a7a7a7;
  text-decoration: line-through;
  margin-bottom: 3px;
  font-size: 12px;
}

.c-productdetails .details-block .c-cmdbtn,
.c-productdetails .details-block .quantitychoose {
  flex: 1;
  margin-right: 10px;
}

.c-productdetails .details-block .approve {
  font-size: 12px;
  margin-bottom: 20px;
}

.c-productdetails .details-block .approve a {
  color: #333;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .c-productdetails {
    padding: 10px 0;
  }

  .c-productdetails .details-block {
    margin-bottom: 20px;
    padding: 10px;
  }

  .pnlcontent .c-productdetails .details-block {
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .c-productdetails .details-block:after {
    display: block;
    content: '';
    height: 2px;
  }

  .c-productdetails .details-block .image {
    margin-bottom: 20px;
  }

  .c-productdetails .details-block .image img {
    max-width: 100%;
    max-height: 260px;
  }

  .c-productdetails .details-block .shop {
    margin: 5px 0 20px;
  }

  .c-productdetails .details-block .shop .c-cmdbtn {
    width: 100%;
  }
}

@media screen and (max-width: $screen-xs) {
  .c-productdetails .details-block {
    display: flex;
    flex-direction: column-reverse;
  }
}


.c-productdetails__table {
  border-collapse: collapse;
  width: 100%;

  td {
    border: 1px solid $color-grey;
    padding: 8px;
  }
}

.c-productdetails--label {
  margin-bottom: 10px;

  &__title {
    display: flex;
    align-items: center;

    h3 {
      margin-right: 10px;
    }
  }
}

.c-productdetails__hazardicon--img {
  display: block;
}

.c-productdetails__hazardcontainer h3 {
  margin-top: 10px;
}