@import '../../styles/variables';

.c-availability {
  display: flex;
  align-items: center;
  height: 25px;
  margin-bottom: 5px;

  &__icon {
    width: auto;
    height: 14px;
    margin-right: 5px;
  }

  &__text {
    font-size: $font-size-xxs;
    display: block;
    line-height: 15px;
  }
}