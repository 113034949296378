@import '../../styles/variables';

.offer-card-container {
  background-color: $color-white;
  box-shadow: 0 1px 0 0 #d7d7d7;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  justify-content: space-between;
  padding: 12px;
  text-align: center;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.1);
    color: $color-black;
  }

  .offer-card-upper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .image-container {
      display: block;

      .image {
        height: 188px;
        margin: auto;
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
        line-height: 22.5px;
        font-size: 15px;
        font-weight: 600;
        margin: 0;
        word-wrap: break-word;

        @media screen and (max-width: $screen-xs) {
          margin: 0 auto 0.123rem;
        }
      }

      .brand {
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
      }
    }
  }

  .offer-card-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    .price-details {
      font-size: 10px;
      line-height: 15px;

      .ord-price {
        font-weight: 700;
      }

      .comp-price {
        color: #7b7b7b;
      }
    }

    .max-per-receipt {
      font-size: 8px;
      font-weight: 600;
      line-height: 12px;
    }
  }
}
